const LayoutBg = () => {
	return (
		<svg
			width="1920"
			height="1080"
			viewBox="0 0 1920 1080"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="none"
		>
			<rect width="1920" height="1080" fill="none" />
			<path
				d="M317.001 1017L651.959 1017L707.295 1058.24L808.233 1058.24L840.001 1081.8L840.001 1097"
				stroke="#0066FF"
				strokeWidth="0.7"
				strokeMiterlimit="10"
				strokeLinejoin="round"
			/>
			<path
				d="M317 1017L651.958 1017L707.295 1058.23L808.233 1058.23L840 1081.8L840 1097"
				stroke="white"
				strokeWidth="0.7"
				strokeMiterlimit="10"
				strokeLinejoin="round"
			/>
			<path
				d="M317 1017L651.958 1017L707.295 1058.23L808.233 1058.23L840 1081.8L840 1097"
				stroke="white"
				strokeWidth="0.7"
				strokeMiterlimit="10"
				strokeLinejoin="round"
			/>
			<path
				d="M317.001 1017L651.959 1017L707.295 1058.24L808.233 1058.24L840.001 1081.8L840.001 1097"
				stroke="#0066FF"
				strokeWidth="0.7"
				strokeMiterlimit="10"
				strokeLinejoin="round"
			/>
			<path
				d="M317.001 1017L651.959 1017L707.295 1058.24L808.233 1058.24L840.001 1081.8L840.001 1097"
				stroke="white"
				strokeOpacity="0.5"
				strokeWidth="0.7"
				strokeMiterlimit="10"
				strokeLinejoin="round"
			/>
			<path
				d="M1603 1017L1268.04 1017L1212.71 1058.24L1111.77 1058.24L1080 1081.8L1080 1097"
				stroke="#0066FF"
				strokeWidth="0.7"
				strokeMiterlimit="10"
				strokeLinejoin="round"
			/>
			<path
				d="M1603 1017L1268.04 1017L1212.71 1058.23L1111.77 1058.23L1080 1081.8L1080 1097"
				stroke="white"
				strokeWidth="0.7"
				strokeMiterlimit="10"
				strokeLinejoin="round"
			/>
			<path
				d="M1603 1017L1268.04 1017L1212.71 1058.23L1111.77 1058.23L1080 1081.8L1080 1097"
				stroke="white"
				strokeWidth="0.7"
				strokeMiterlimit="10"
				strokeLinejoin="round"
			/>
			<path
				d="M1603 1017L1268.04 1017L1212.71 1058.24L1111.77 1058.24L1080 1081.8L1080 1097"
				stroke="#0066FF"
				strokeWidth="0.7"
				strokeMiterlimit="10"
				strokeLinejoin="round"
			/>
			<path
				d="M1603 1017L1268.04 1017L1212.71 1058.24L1111.77 1058.24L1080 1081.8L1080 1097"
				stroke="white"
				strokeOpacity="0.5"
				strokeWidth="0.7"
				strokeMiterlimit="10"
				strokeLinejoin="round"
			/>
			<path
				d="M-8.99997 800L31.0429 838.305L31.0429 994.061L57.2962 1019.17L99.9909 1019.17L169.469 1085.64L245.842 1085.64L284.559 1048.6L503.867 1048.6L659 1197L-9 1197"
				stroke="white"
				strokeOpacity="0.5"
			/>
			<path
				d="M-8.99997 800L31.0429 838.304L31.0429 994.06L57.2962 1019.17L99.9909 1019.17L169.469 1085.64L245.842 1085.64L284.559 1048.6L503.867 1048.6L659 1197L-9 1197"
				stroke="#0066FF"
			/>
			<path
				d="M-8.99997 800L31.0429 838.304L31.0429 994.06L57.2962 1019.17L99.9909 1019.17L169.469 1085.64L245.842 1085.64L284.559 1048.6L503.867 1048.6L659 1197L-9 1197"
				stroke="#0066FF"
			/>
			<path
				d="M-8.99997 800L31.0429 838.305L31.0429 994.061L57.2962 1019.17L99.9909 1019.17L169.469 1085.64L245.842 1085.64L284.559 1048.6L503.867 1048.6L659 1197L-9 1197"
				stroke="#0066FF"
			/>
			<path
				d="M-8.99997 800L31.0429 838.305L31.0429 994.061L57.2962 1019.17L99.9909 1019.17L169.469 1085.64L245.842 1085.64L284.559 1048.6L503.867 1048.6L659 1197L-9 1197"
				stroke="white"
				strokeOpacity="0.3"
			/>
			<path
				d="M1929 800L1888.96 838.305L1888.96 994.061L1862.7 1019.17L1820.01 1019.17L1750.53 1085.64L1674.16 1085.64L1635.44 1048.6L1416.13 1048.6L1261 1197L1929 1197"
				stroke="white"
				strokeOpacity="0.5"
			/>
			<path
				d="M1929 800L1888.96 838.304L1888.96 994.06L1862.7 1019.17L1820.01 1019.17L1750.53 1085.64L1674.16 1085.64L1635.44 1048.6L1416.13 1048.6L1261 1197L1929 1197"
				stroke="#0066FF"
			/>
			<path
				d="M1929 800L1888.96 838.304L1888.96 994.06L1862.7 1019.17L1820.01 1019.17L1750.53 1085.64L1674.16 1085.64L1635.44 1048.6L1416.13 1048.6L1261 1197L1929 1197"
				stroke="#0066FF"
			/>
			<path
				d="M1929 800L1888.96 838.305L1888.96 994.061L1862.7 1019.17L1820.01 1019.17L1750.53 1085.64L1674.16 1085.64L1635.44 1048.6L1416.13 1048.6L1261 1197L1929 1197"
				stroke="#0066FF"
			/>
			<path
				d="M1929 800L1888.96 838.305L1888.96 994.061L1862.7 1019.17L1820.01 1019.17L1750.53 1085.64L1674.16 1085.64L1635.44 1048.6L1416.13 1048.6L1261 1197L1929 1197"
				stroke="white"
				strokeOpacity="0.3"
			/>
			<path
				d="M207.835 114L52.1548 114L27.0533 139.396L27.0533 180.696L27.0533 489.5L52.1548 514.602L52.1548 819"
				stroke="#0066FF"
				strokeWidth="0.7"
			/>
			<path
				d="M207.835 114L52.1548 114L27.0533 139.396L27.0533 180.696L27.0533 489.5L52.1548 514.602L52.1548 819"
				stroke="#0066FF"
				strokeWidth="0.7"
			/>
			<path
				d="M207.835 114L52.1548 114L27.0533 139.396L27.0533 180.696L27.0533 489.5L52.1548 514.602L52.1548 819"
				stroke="white"
				strokeOpacity="0.7"
				strokeWidth="0.7"
			/>
			<path
				d="M207.835 114L52.1548 114L27.0533 139.396L27.0533 180.696L27.0533 489.5L52.1548 514.602L52.1548 819"
				stroke="#0066FF"
				strokeWidth="0.7"
			/>
			<path
				d="M207.835 114L52.1548 114L27.0533 139.396L27.0533 180.696L27.0533 489.5L52.1548 514.602L52.1548 819"
				stroke="white"
				strokeOpacity="0.39"
				strokeWidth="0.7"
			/>
			<path
				d="M1712 114L1867.68 114L1892.78 139.396L1892.78 180.696L1892.78 489.5L1867.68 514.602L1867.68 819"
				stroke="#0066FF"
				strokeWidth="0.7"
			/>
			<path
				d="M1712 114L1867.68 114L1892.78 139.396L1892.78 180.696L1892.78 489.5L1867.68 514.602L1867.68 819"
				stroke="#0066FF"
				strokeWidth="0.7"
			/>
			<path
				d="M1712 114L1867.68 114L1892.78 139.396L1892.78 180.696L1892.78 489.5L1867.68 514.602L1867.68 819"
				stroke="white"
				strokeOpacity="0.7"
				strokeWidth="0.7"
			/>
			<path
				d="M1712 114L1867.68 114L1892.78 139.396L1892.78 180.696L1892.78 489.5L1867.68 514.602L1867.68 819"
				stroke="#0066FF"
				strokeWidth="0.7"
			/>
			<path
				d="M1712 114L1867.68 114L1892.78 139.396L1892.78 180.696L1892.78 489.5L1867.68 514.602L1867.68 819"
				stroke="white"
				strokeOpacity="0.5"
				strokeWidth="0.7"
			/>
			<path
				d="M121 33.0001L37.3935 33.0001L14.8199 51.7667L14.8199 82.218L-31.9999 124"
				stroke="#0066FF"
			/>
			<path
				d="M121 33.0001L37.3935 33.0001L14.8199 51.7667L14.8199 82.218L-31.9999 124"
				stroke="#0066FF"
			/>
			<path
				d="M121 33.0001L37.3935 33.0001L14.8199 51.7667L14.8199 82.218L-31.9999 124"
				stroke="#0066FF"
			/>
			<path
				d="M121 33.0001L37.3935 33.0001L14.8199 51.7667L14.8199 82.218L-31.9999 124"
				stroke="white"
				strokeOpacity="0.5"
			/>
			<path
				d="M1799 33.0001L1882.61 33.0001L1905.18 51.7667L1905.18 82.218L1952 124"
				stroke="#0066FF"
			/>
			<path
				d="M1799 33.0001L1882.61 33.0001L1905.18 51.7667L1905.18 82.218L1952 124"
				stroke="#0066FF"
			/>
			<path
				d="M1799 33.0001L1882.61 33.0001L1905.18 51.7667L1905.18 82.218L1952 124"
				stroke="#0066FF"
			/>
			<path
				d="M1799 33.0001L1882.61 33.0001L1905.18 51.7667L1905.18 82.218L1952 124"
				stroke="white"
				strokeOpacity="0.5"
			/>
		</svg>
	);
};

export default LayoutBg;

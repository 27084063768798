const RoscosmosIcon = () => {
	return (
		<svg width="50" height="32" viewBox="0 0 50 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.9954 4.83516L25.1349 4.79768C18.3721 6.91539 13.014 10.5324 12.9023 13.1936C12.7814 16.0422 18.7163 16.7075 26.1488 14.6835C33.5814 12.6595 39.7023 8.70514 39.8233 5.85653C39.8884 4.37601 38.3163 3.49519 35.7582 3.26093C38.8837 3.40148 40.8372 4.31978 40.8372 5.95961C40.8372 9.07996 33.7395 13.634 24.9861 16.1359C16.2326 18.6378 9.13489 18.1318 9.13489 15.0114C9.14419 11.8911 16.2512 7.33706 24.9954 4.83516Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.5861 21.2521L34.5582 0L32.5675 16.7075L28.9395 12.5095L12.5861 21.2521Z"
				fill="#ED1941"
			/>
			<path
				d="M3.87907 28.3455C3.87907 29.2451 3.23721 30.1072 2.13023 30.1072H1.00465V31.8688H0V26.5745H1.87907C3.24651 26.5839 3.87907 27.3242 3.87907 28.3455ZM2.87442 28.3455C2.87442 27.8489 2.48372 27.5865 2 27.5865H1.00465V29.0952H2.0093C2.48372 29.1045 2.87442 28.7859 2.87442 28.3455Z"
				fill="white"
			/>
			<path
				d="M10.0465 29.2263C10.0465 30.7537 8.80932 32 7.29304 32C5.77676 32 4.53955 30.7537 4.53955 29.2263C4.53955 27.699 5.77676 26.4527 7.29304 26.4527C8.80932 26.4527 10.0465 27.699 10.0465 29.2263ZM9.04188 29.2263C9.04188 28.2518 8.26048 27.4647 7.29304 27.4647C6.3256 27.4647 5.5442 28.2518 5.5442 29.2263C5.5442 30.2009 6.3256 30.988 7.29304 30.988C8.26048 30.988 9.04188 30.2009 9.04188 29.2263Z"
				fill="white"
			/>
			<path
				d="M14.8558 30.4445L15.2372 31.3628C14.7907 31.7751 14.1209 32 13.5349 32C11.9907 32 10.7349 30.7537 10.7349 29.2263C10.7349 27.699 11.9907 26.4527 13.5349 26.4527C14.1209 26.4527 14.7907 26.6776 15.2372 27.0805L14.8558 27.9988C14.5209 27.6709 14.0558 27.4647 13.5535 27.4647C12.5488 27.4647 11.7395 28.2518 11.7395 29.2263C11.7395 30.2009 12.5488 30.988 13.5535 30.988C14.0651 30.988 14.5302 30.7912 14.8558 30.4445Z"
				fill="white"
			/>
			<path
				d="M20.7907 31.8782H19.5256L17.5349 29.5637V31.8782H16.5303V26.5839H17.5349V28.7672L19.3954 26.5745H20.6605L18.4093 29.1045L20.7907 31.8782Z"
				fill="white"
			/>
			<path
				d="M26.5023 29.2263C26.5023 30.7537 25.2651 32 23.7489 32C22.2326 32 20.9954 30.7537 20.9954 29.2263C20.9954 27.699 22.2326 26.4527 23.7489 26.4527C25.2651 26.4527 26.5023 27.699 26.5023 29.2263ZM25.4977 29.2263C25.4977 28.2518 24.7163 27.4647 23.7489 27.4647C22.7814 27.4647 22 28.2518 22 29.2263C22 30.2009 22.7814 30.988 23.7489 30.988C24.7163 30.988 25.4977 30.2009 25.4977 29.2263Z"
				fill="white"
			/>
			<path
				d="M31.3116 30.4445L31.693 31.3628C31.2465 31.7751 30.5767 32 29.9907 32C28.4465 32 27.1907 30.7537 27.1907 29.2263C27.1907 27.699 28.4465 26.4527 29.9907 26.4527C30.5767 26.4527 31.2465 26.6776 31.693 27.0805L31.3116 27.9988C30.9767 27.6709 30.5116 27.4647 30.0093 27.4647C29.0046 27.4647 28.1953 28.2518 28.1953 29.2263C28.1953 30.2009 29.0046 30.988 30.0093 30.988C30.5209 30.988 30.986 30.7912 31.3116 30.4445Z"
				fill="white"
			/>
			<path
				d="M38.7628 31.8782H37.7023L37.2651 28.4111L35.8883 31.8782H35.386L34.0093 28.4111L33.5721 31.8782H32.5116L33.2651 26.5839H34.2697L35.6465 29.9853L37.0232 26.5839H38.0279L38.7628 31.8782Z"
				fill="white"
			/>
			<path
				d="M44.8094 29.2263C44.8094 30.7537 43.5721 32 42.0559 32C40.5396 32 39.3024 30.7537 39.3024 29.2263C39.3024 27.699 40.5396 26.4527 42.0559 26.4527C43.5721 26.4527 44.8094 27.699 44.8094 29.2263ZM43.8047 29.2263C43.8047 28.2518 43.0233 27.4647 42.0559 27.4647C41.0884 27.4647 40.307 28.2518 40.307 29.2263C40.307 30.2009 41.0884 30.988 42.0559 30.988C43.0233 30.988 43.8047 30.2009 43.8047 29.2263Z"
				fill="white"
			/>
			<path
				d="M49.6186 30.4445L50 31.3628C49.5535 31.7751 48.8837 32 48.2977 32C46.7535 32 45.4977 30.7537 45.4977 29.2263C45.4977 27.699 46.7535 26.4527 48.2977 26.4527C48.8837 26.4527 49.5535 26.6776 50 27.0805L49.6186 27.9988C49.2837 27.6709 48.8186 27.4647 48.3163 27.4647C47.3116 27.4647 46.5023 28.2518 46.5023 29.2263C46.5023 30.2009 47.3116 30.988 48.3163 30.988C48.8279 30.988 49.2837 30.7912 49.6186 30.4445Z"
				fill="white"
			/>
		</svg>
	);
};

export default RoscosmosIcon;

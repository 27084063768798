const mksAboutInfo = {
	id: 1,
	name: "Международная космическая станция",
	video: [
		{
			slug: "videoBlock",
			value: { id: 1, video: require("../images/MksModal/main/ISS.mp4")},
			
		},
	],
	slider: [
		{
			slug: "modalSlider",
			value: [
				{ id: 1, image: require("../images/MksModal/main/modal-01.png")},
				{ id: 2, image: require("../images/MksModal/main/modal-02.png")},
				{ id: 3, image: require("../images/MksModal/main/modal-03.png")},
				{ id: 4, image: require("../images/MksModal/main/modal-04.png")},
				{ id: 5, image: require("../images/MksModal/main/modal-05.png")},
				{ id: 6, image: require("../images/MksModal/main/modal-06.png")}
			]
		},
	],
	content: [
		{
			slug: "textSlider",
			value: [
				{
					id: 1,
					content: [
						{
						slug: "heading",
						value: "Международная космическая станция"
						},
						{
						slug: "paragraph",
						value: "Международная космическая станция, сокращенно МКС (англ. International Space Station, сокр. ISS) - крупнейший международный проект, пилотируемая орбитальная станция, используемая как многоцелевой космический исследовательский комплекс."
						},
						{
						slug: "paragraph",
						value: "Сегодня МКС представляет собой совместный проект, в котором участвуют космические агентства Роскосмос, NASA (США), JAXA (Япония), CSA (Канада), ESA (страны Европы)."
						},
						{
						slug: "paragraph",
						value: "Программа создания Международной космической станции — логическое продолжение сегодняшнего этапа развития космонавтики. Усилиями в первую очередь советской и российской космонавтики доказана возможность длительных пилотируемых полетов, определены направления деятельности для создания внеземных производств."
						},
					]
				},
				{
					id: 2,
					content: [
						{
						slug: "paragraph",
						value: "Проект МКС объединяет ресурсы, научно-технические достижения и опыт западных стран и России, способствует развитию национальных экономик и эффективному использованию космоса в интересах мирового сообщества, взаимопониманию государств. Создание Международной космической станции является необходимой ступенью развития Человечества в плане использования ресурсов Земли и Солнечной системы."
						},
						{
						slug: "paragraph",
						value: "В создании станции принимали участие: Россия, США, Канада, Япония, Германия, Франция, Испания, Италия, Дания, Бельгия, Нидерланды, Норвегия, Швеция и Швейцария. Реализация программы МКС была начата 20 ноября 1998 года после старта российского функционального грузового блока «Заря», который стал базовым элементом МКС. Роль России в этом проекте — одна из ведущих. Опыт, накопленный российской космонавтикой за период эксплуатации собственных пилотируемых орбитальных станций, во многом стал практической базой для создания МКС."
						},
					]
				},
				{
					id: 3,
					content: [
						{
						slug: "paragraph",
						value: "С 2000 года станция является постоянно обитаемой. На её борту, сменяя друг друга, по несколько месяцев работают экипажи основных экспедиций, в состав которых входят российские космонавты и американские астронавты. До февраля 2003 года смена этих экипажей производилась с помощью американских кораблей «Спейс шаттл». Российские корабли «Союз», имеющие большой ресурс пребывания в космосе, служили в качестве средства спасения экипажа в случае необходимости срочного покидания станции."
						},
						{
						slug: "paragraph",
						value: "Станция преимущественно делится на две основные части: российский и американский сегменты МКС. Российский сегмент (РС МКС) управляется из подмосковного Центра управления полетами в г. Королёве, американский сегмент (АС МКС) — из аналогичного по назначению центра в г. Хьюстон, штат Техас."
						},
					]
				},
			]
		},
	]
}

const mksStructure = [
	{
		id: 1,
		name: "Функционально-грузовой блок «Заря»",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/MksModal/1/1_Zarya.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/MksModal/1/modal-01.png")},
					{ id: 2, image: require("../images/MksModal/1/modal-02.png")},
					{ id: 3, image: require("../images/MksModal/1/modal-03.png")},
					{ id: 4, image: require("../images/MksModal/1/modal-04.png")},
					{ id: 5, image: require("../images/MksModal/1/modal-05.png")}
				]
			},
		],
		content: [
			{
				slug: "textSlider",
				value: [
					{
						id: 1,
						content: [
							{
							slug: "heading",
							value: "Функционально-грузовой модуль «Заря»"
							},
							{
							slug: "modalInfo",
							value: {
								"symbol": 'ФГБ',
								"start": '20 ноября 1998 г',
							}
							},
							{
							slug: "paragraph",
							value: "Развертывание Международной космической станции (МКС) на околоземной орбите началось запуском 20 ноября 1998 года функционально-грузового блока (ФГБ) «Заря», стал первым элементом МКС. Он разработан и изготовлен Центром им. М.В. Хруничева. С этого модуля началась сборка МКС на околоземной орбите. На начальной стадии сборки ФГБ обеспечивал управление полетом связки модулей, электропитание, связь, прием, хранение и перекачку топлива."
							},
							{
							slug: "paragraph",
							value: "Компоновка ФГБ включает в себя приборно-грузовой отсек и герметичный адаптер, предназначенный для размещения бортовых систем, обеспечивающих механическую стыковку с другими модулями МКС и прибывающими на МКС кораблями."
							},
						]
					},
					{
						id: 2,
						content: [
							{
							slug: "paragraph",
							value: "Внутреннее пространство модуля разделено на две зоны: приборную и жилую. В приборной зоне размещены блоки бортовых систем. Жилая зона предназначена для работы экипажа. В ней находятся элементы систем контроля и управления бортовым комплексом. Приборная зона отделена от жилой зоны панелями интерьера."
							},
							{
							slug: "modalTechnical",
							value: {
								title: "Основные технические характеристики модуля:",
								content: [
									{id: '1', name: "Масса на орбите", value: "20 260 кг"},
									{id: '2', name: "Длина по корпусу", value: "12 990 мм"},
									{id: '3', name: "Максимальный диаметр", value: "4 100 мм"},
									{id: '4', name: "Объем герметичных отсеков", value: "71,5 м³"},
									{id: '5', name: "Размах солнечных батарей", value: "24 400 мм"},
									{id: '6', name: "Площадь фотоэлектрических элементов", value: "28 м2"},
									{id: '7', name: "Гарантированная среднесуточная мощность электроснабжения напряжением 28 В", value: "3 кВт"},
									{id: '8', name: "Мощность электроснабжения американского сегмента", value: "до 2 кВт"},
									{id: '9', name: "Масса заправляемого топлива", value: "до 6 100 кг"},
								]
							}
							},
						]
					},
					{
						id: 3,
						content: [
							{
							slug: "modalTechnical",
							value: {
								title: "Параметры орбиты выведения модуля:",
								content: [
									{id: '1', name: "Высота в перигее", value: "180 км"},
									{id: '2', name: "Высота в апогее", value: "340 км"},
									{id: '3', name: "Период обращения", value: "89.6 мин"},
									{id: '4', name: "Наклонение", value: "51.6°"},
									{id: '5', name: "Высота орбиты сборки", value: "385 км"},
									{id: '6', name: "Высота рабочей орбиты", value: "350-500 км"},
									{id: '7', name: "Длительность функционирования на орбите", value: "15 лет"},
								]
							}
							},
						]
					},
				]
			},
		]
	},
	{
		id: 2,
		name: "Соединительный модуль «Юнити»",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/MksModal/2/2_Unity.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/MksModal/2/modal-01.png")},
					{ id: 2, image: require("../images/MksModal/2/modal-02.png")}
				]
			},
		],
		content: [
			{
				slug: "textSlider",
				value: [
					{
						id: 1,
						content: [
							{
							slug: "heading",
							value: "Соединительный модуль «Юнити» (Unity)"
							},
							{
							slug: "modalInfo",
							value: {
								"symbol": 'NODE1',
								"start": '04 декабря 1998 г',
								"finish": '07 декабря 1998 г',
							}
							},
							{
							slug: "paragraph",
							value: "Модуль «Юнити» (англ. Unity) - выполненная из алюминия цилиндрическая конструкция с шестью люками для подсоединения других компонентов станции, образует коридор, соединяющий жилые и рабочие помещения Международной космической станции. Этот узел соединен с функциональным грузовым блоком «Заря» и является первым крупным американским компонентом Международной космической станции, выведенным в космос."
							},
						]
					},
					{
						id: 2,
						content: [
							{
							slug: "paragraph",
							value: "Этот модуль служит коридором, соединяющим американский лабораторный модуль, жилые отсеки и воздушный шлюз. Через модуль «Юнити» проходят важные системы и коммуникации, такие как трубопроводы подачи жидкостей, газов, средства регулирования среды, системы жизнеобеспечения, электроснабжения и передачи данных. Установлено более 50 тыс. механических деталей, проложено более 200 трубопроводов для жидкостей и газов, а также внутренние и внешние электрические кабели, длина которых достигает шести миль. Наземный монтаж оборудования и систем осуществлялся группой специалистов компании Boeing и ее субподрядчиков."
							},
						]
					},
				]
			},
		]
	},
	{
		id: 3,
		name: "Служебный модуль «Звезда»",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/MksModal/3/3_Zvezda.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/MksModal/3/modal-01.png")},
					{ id: 2, image: require("../images/MksModal/3/modal-02.png")},
					{ id: 3, image: require("../images/MksModal/3/modal-03.png")},
					{ id: 4, image: require("../images/MksModal/3/modal-04.png")},
					{ id: 5, image: require("../images/MksModal/3/modal-05.png")}
				]
			},
		],
		content: [
			{
				slug: "textSlider",
				value: [
					{
						id: 1,
						content: [
							{
							slug: "heading",
							value: "Служебный модуль «Звезда»"
							},
							{
							slug: "modalInfo",
							value: {
								"symbol": 'СМ',
								"start": '12 июля 2000 г',
								"finish": '26 июля 2000 г',
							}
							},
							{
							slug: "paragraph",
							value: "Служебный модуль (СМ) «Звезда» является основным модулем Российского сегмента (РС) МКС. Головным разработчиком СМ «Звезда» выступила РКК «Энергия», основным субподрядчиком - Центр им.М.В.Хруничева."
							},
							{
								slug: "modalList",
								value: {
									title: "Предназначен для:",
									content: [
										{id: '1', name: "управления всеми модулями РС МКС;"},
										{id: '2', name: "решения задач управления ориентацией всей станции со стороны РС МКС;"},
										{id: '3', name: "обеспечения жизнедеятельности экипажа станции;"},
										{id: '4', name: "взаимодействия с центральным компьютером Американского сегмента при управлении всей МКС."},
									]
								}
							},
						]
					},
					{
						id: 2,
						content: [
							{
							slug: "boldText",
							value: "Запуск  состоялся 12 июля 2000 года. 26 июля модуль состыковался с ранее состыкованными «Заря» и «Юнити»."
							},
							{
								slug: "modalTechnical",
								value: {
									title: "Основные технические характеристики модуля:",
									content: [
										{id: '1', name: "Масса на орбите после отделения от РН", value: "20 295 кг"},
										{id: '2', name: "Длина по корпусу", value: "13.11 м"},
										{id: '3', name: "Максимальный диаметр", value: "4.35 м"},
										{id: '4', name: "Объем герметичных отсеков", value: "89.0 м³"},
										{id: '5', name: "Объем обитания экипажа", value: "46.7 м³"},
										{id: '6', name: "Обеспечение жизнедеятельности экипажа", value: "до 6 человек"},
										{id: '7', name: "Размах солнечных батарей", value: "29.73 м"},
										{id: '8', name: "Площадь фотоэлектрических элементов", value: "76 м2"},
										{id: '9', name: "Максимальная выходная мощность солнечных батарей", value: "13.8 кВт"},
										{id: '10', name: "Длительность функционирования на орбите", value: "15 лет"},
									]
								}
								},
						]
					},
					{
						id: 3,
						content: [
							{
							slug: "paragraph",
							value: "Конструктивно СМ «Звезда» состоит из четырех отсеков: трех герметичных – переходного отсека, рабочего отсека и промежуточной камеры, а также негерметичного агрегатного отсека, в котором размещена объединенная двигательная установка."
							},
							{
							slug: "paragraph",
							value: "Корпус герметичных отсеков выполнен из алюминиево-магниевого сплава и представляет собой сварную конструкцию, состоящую из блоков цилиндрической, конической и сферической формы."
							},
							{
							slug: "paragraph",
							value: "Переходный отсек предназначен для обеспечения перехода членов экипажа между служебным модулем и другими модулями МКС. Он также выполняет функции шлюзового отсека при выходе членов экипажа в открытый космос, для чего на боковой крышке имеется клапан сброса давления. На наружной поверхности переходного отсека имеются три комплекта антенн системы «Курс» для обеспечения автоматической стыковки космических аппаратов, стыковочные мишени, агрегаты СТР, блок дозаправки двигательной установки, телекамера, бортовые огни и другое оборудование. Наружная поверхность закрыта панелями с многослойной экранно-вакуумной теплоизоляцией и противометеоритными экранами."
							},
						]
					},
					{
						id: 4,
						content: [
							{
							slug: "paragraph",
							value: "Рабочий отсек предназначен для размещения основной части бортовых систем и оборудования СМ, для жизни и работы экипажа. В рабочем отсеке имеется восемь иллюминаторов. Жилые помещения оборудованы средствами обеспечения жизнедеятельности экипажа."
							},
							{
							slug: "paragraph",
							value: "В зоне малого диаметра рабочего отсека находится центральный пост управления станцией с блоками контроля и аварийно-предупредительными пультами, а в зоне большого диаметра имеются две персональные каюты, санитарный отсек с умывальником и ассенизационным устройством, кухня с холодильником-морозильником, рабочий стол со средствами фиксации, медицинская аппаратура, тренажеры для физических упражнений, небольшая шлюзовая камера для отделения контейнеров с отходами и малых КА."
							},
							{
							slug: "paragraph",
							value: "Промежуточная камера предназначена для обеспечения перехода космонавтов между СМ и кораблями «Союз» или «Прогресс», пристыкованными к кормовому стыковочному агрегату."
							},
							{
							slug: "paragraph",
							value: "Негерметичный агрегатный отсек вокруг ПрК предназначен для размещения агрегатов объединенной двигательной установки."
							},
						]
					},
				]
			},
		]
	},
	{
		id: 4,
		name: "Лабораторный модуль «Дестини»",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/MksModal/4/4_Destiny.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/MksModal/4/modal-01.png")},
					{ id: 2, image: require("../images/MksModal/4/modal-02.png")}
				]
			},
		],
		content: [
			{
				slug: "textSlider",
				value: [
					{
						id: 1,
						content: [
							{
								slug: "heading",
								value: "Лабораторный модуль «Дестини» (Destiny)"
								},
								{
								slug: "modalInfo",
								value: {
									"symbol": 'LAB',
									"start": '08 февраля 2001 г',
									"finish": '10 февраля 2001 г',
								}
								},
								{
								slug: "paragraph",
								value: "9 февраля 2001 года экипаж космического корабля шаттл «Атлантис» (Atlantis) STS-98 доставил и пристыковал к станции американский лабораторный модуль «Дестини» (англ. Destiny). Научный модуль «Дестини» состоит из трёх цилиндрических секций и двух оконечных урезанных конусов, которые содержат герметичные люки, используемые экипажем для входа в модуль и выхода из него. «Дестини» пристыкован к переднему стыковочному узлу модуля «Юнити»."
								},
						]
					},
					{
						id: 2,
						content: [
							{
							slug: "paragraph",
							value: "Научное и вспомогательное оборудование внутри модуля Destiny смонтировано в стандартных блоках полезной нагрузки ISPR (International Standard Payload Racks). Всего «Дестини» содержит 23 блока ISPR - по шесть на правом, на левом борту и потолке, и пять на полу."
							},
							{
							slug: "paragraph",
							value: "Модуль Destiny имеет систему жизнеобеспечения, которая обеспечивает электроснабжение, очистку воздуха, а также контроль температуры и влажности в модуле. В герметичном модуле астронавты могут выполнять исследования в различных областях научных знаний: в медицине, технологии, биотехнологии, физике, материаловедении, и изучении Земли. Модуль изготовлен американской компанией Boeing."
							},
						]
					},
				]
			},
		]
	},
	{
		id: 5,
		name: "Шлюзовая камера «Квест»",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/MksModal/5/5_Quest.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/MksModal/5/modal-01.png")},
					{ id: 2, image: require("../images/MksModal/5/modal-02.png")}
				]
			},
		],
		content: [
			{
				slug: "textSlider",
				value: [
					{
						id: 1,
						content: [
							{
								slug: "heading",
								value: "Универсальная шлюзовая камера «Квест» (Quest)"
								},
								{
								slug: "modalInfo",
								value: {
									"symbol": 'A/L',
									"start": '12 июля 2001 г',
									"finish": '15 июля 2001 г',
								}
								},
								{
								slug: "paragraph",
								value: "Универсальная шлюзовая камера «Квест» (англ. Quest) была доставлена к МКС космическим шаттлом «Атлантис» (Atlantis) STS-104 15 июля 2001 года и с помощью дистанционного манипулятора станции «Канадарм 2» (Canadarm 2) была извлечена из грузового отсека «Атлантис», перенесена и пристыкована к причалу американского модуля «Юнити»."
								},
								{
								slug: "paragraph",
								value: "Универсальная шлюзовая камера «Квест» предназначена для обеспечения выходов в открытый космос экипажей МКС с использованием как американских скафандров, так и российских скафандров «Орлан»."
								},
						]
					},
					{
						id: 2,
						content: [
							{
								slug: "paragraph",
								value: "До установки этой шлюзовой камеры выходы в открытый космос производились либо через переходной отсек служебного модуля «Звезда» (в российских скафандрах), либо через пристыкованные шаттлы (в американских скафандрах)."
								},
								{
								slug: "paragraph",
								value: "После установки и приведения в рабочее состояние шлюзовая камера стала одной из основных систем для обеспечения выхода в открытый космос и возврата на МКС и позволила применять любую из существующих систем скафандров или обе одновременно."
								},
								{
								slug: "paragraph",
								value: "Шлюзовая камера представляет собой герметичный модуль, состоящий из двух основных сегментов: отсека экипажа, через который астронавты выходят из МКС в открытый космос, и отсека оборудования, где хранятся агрегаты и скафандры для обеспечения внекорабельной деятельности, а также так называемые агрегаты для ночного «вымывания», которые используются в ночь перед выходом в открытый космос для вымывания азота из крови астронавта в процессе понижения атмосферного давления. Эта процедура позволяет избежать проявления признаков декомпрессии после возврата космонавта из открытого космоса и наддува отсека."
								},
						]
					},
				]
			},
		]
	},
	{
		id: 6,
		name: "Соединительный модуль «Гармония»",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/MksModal/6/6_Harmony.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/MksModal/6/modal-01.png")},
					{ id: 2, image: require("../images/MksModal/6/modal-02.png")}
				]
			},
		],
		content: [
			{
				slug: "textSlider",
				value: [
					{
						id: 1,
						content: [
							{
								slug: "heading",
								value: "Соединительный модуль «Гармония» (Harmony)"
								},
								{
								slug: "modalInfo",
								value: {
									"symbol": 'NODE2',
									"start": '23 октября 2007 г',
									"finish": '26 октября 2007 г',
								}
								},
								{
								slug: "paragraph",
								value: "Модуль «Гармония» (англ. Harmony) доставлен на МКС на борту шаттла «Дискавери» (Discovery) STS-120 и 26 октября 2007 года был временно установлен на левый стыковочный узел модуля «Юнити» МКС. 14 ноября 2007 года модуль «Гармония» перемещен экипажем МКС-16 на постоянное место - на передний стыковочный узел модуля «Дестини». Предварительно на передний стыковочный узел модуля «Гармония» был перенесен стыковочный модуль кораблей шаттл."
								},
						]
					},
					{
						id: 2,
						content: [
							{
								slug: "paragraph",
								value: "Модуль «Гармония» является соединительным элементом для двух исследовательских лабораторий: европейской «Коламбус» и японской «Кибо». Он обеспечивает электропитание присоединённых к нему модулей и обмен данными. Для обеспечения возможности увеличения численности постоянно действующего экипажа МКС в модуле установлена дополнительная система обеспечения жизнедеятельности. Кроме того, модуль оборудован тремя дополнительными спальными местами для членов экипажа МКС."
								},
						]
					},
				]
			},
		]
	},
	{
		id: 7,
		name: "Лабораторный модуль «Коламбус»",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/MksModal/7/7_Columbus.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/MksModal/7/modal-01.png")},
					{ id: 2, image: require("../images/MksModal/7/modal-02.png")}
				]
			},
		],
		content: [
			{
				slug: "textSlider",
				value: [
					{
						id: 1,
						content: [
							{
								slug: "heading",
								value: "Лабораторный модуль «Коламбус» (Columbus)"
								},
								{
								slug: "modalInfo",
								value: {
									"symbol": 'COL',
									"start": '07 февраля 2008 г',
									"finish": '12 февраля 2008 г',
								}
								},
								{
								slug: "paragraph",
								value: "Модуль «Коламбус» (англ. Columbus) доставлен на МКС на борту шаттла «Атлантис» (Atlantis) STS-122 и пристыкован к модулю «Гармония» (Harmony) 11 февраля 2008 года. Модуль «Коламбус» построен по заказу Европейского космического агентства консорциумом европейских аэрокосмических фирм. Он представляет собой научную лабораторию, предназначенную для проведения физических, материаловедческих, медико-биологических и иных экспериментов."
								},
						]
					},
					{
						id: 2,
						content: [
							{
								slug: "paragraph",
								value: "Внутри модуля имеется 10 унифицированных мест (ячеек) для установки контейнеров с научной аппаратурой и оборудованием. На внешней поверхности модуля имеется четыре места для крепления научной аппаратуры, предназначенной для проведения исследований и экспериментов в условиях открытого космоса (изучение солнечно-земных связей, анализ воздействия на оборудование и материалы длительного пребывания в космосе, эксперименты по выживанию бактерий в экстремальных условиях и т.д.)."
								},
								{
								slug: "paragraph",
								value: "На момент доставки на МКС в модуле были уже установлены 5 контейнеров с научной аппаратурой для проведения научных экспериментов в области биологии, физиологии и материаловедения."
								},
						]
					},
				]
			},
		]
	},
	{
		id: 8,
		name: "Грузовой модуль (1-й доставленный элемент модуля «Кибо»)",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/MksModal/8/8_KiboLogistics.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/MksModal/8/modal-01.png")},
					{ id: 2, image: require("../images/MksModal/8/modal-02.png")}
				]
			},
		],
		content: [
			{
				slug: "textSlider",
				value: [
					{
						id: 1,
						content: [
							{
								slug: "heading",
								value: "Грузовой модуль (1-й доставленный элемент модуля «Кибо»)"
								},
								{
								slug: "modalInfo",
								value: {
									"symbol": 'ELM-PS',
									"start": '11 марта 2008 г',
									"finish": '14 марта 2008 г',
								}
								},
								{
								slug: "paragraph",
								value: "Японский экспериментальный модуль «Кибо» (Kibo) является первой научной орбитальной лабораторий Японии. Это самый большой модуль МКС. Международная аббревиатура - JEM (от англ. Japanese Experiment Module). Его разработчиком является Японское агентство аэрокосмических исследований (JAXA)."
								},
						]
					},
					{
						id: 2,
						content: [
							{
								slug: "paragraph",
								value: "Модуль «Кибо» предназначен для проведения широкого спектра научных исследований и экспериментов по изучению процессов, происходящих на Земле и в околоземном пространстве (состояние озонового слоя, глобальное потепление, выращивание кристаллов, воздействие микрогравитации и радиации на растения, животных и людей, астрономические исследования и т.д.)."
								},
						]
					},
				]
			},
		]
	},
	{
		id: 9,
		name: "Научно-исследовательский модуль «Кибо»",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/MksModal/9/9_KiboLab.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/MksModal/9/modal-01.png")},
					{ id: 2, image: require("../images/MksModal/9/modal-02.png")},
					{ id: 3, image: require("../images/MksModal/9/modal-03.png")}
				]
			},
		],
		content: [
			{
				slug: "textSlider",
				value: [
					{
						id: 1,
						content: [
							{
								slug: "heading",
								value: "Научно-исследовательский модуль «Кибо»"
								},
								{
								slug: "modalInfo",
								value: {
									"symbol": 'ELM-PS',
									"start": '11 марта 2008 г',
									"finish": '14 марта 2008 г',
								}
								},
								{
								slug: "paragraph",
								value: "Японский экспериментальный модуль «Кибо» состоит из четырех основных компонентов: научной лаборатории, экспериментального грузового модуля (герметичная и негерметичная части), дистанционного манипулятора и внешней негерметичной платформы."
								},
								{
								slug: "paragraph",
								value: "Научная лаборатория модуля «Кибо» представляет собой герметичный отсек цилиндрической формы. Она предназначена для подготовки и проведения научных экспериментов. В научной лаборатории модуля «Кибо» могут работать одновременно четыре астронавта."
								},
						]
					},
					{
						id: 2,
						content: [
							{
								slug: "paragraph",
								value: "Герметичная часть экспериментального грузового модуля (ELM-PS) пристыкована к верхнему люку лабораторного модуля и предназначена для хранения оборудования и запасных частей, необходимых при проведении экспериментов, а также результатов проведенных экспериментов. Во внутреннем объеме модуля поддерживается температура и давление как в лабораторном модуле, и астронавты имеют возможность беспрепятственного перемещаться между грузовым модулем и лабораторным модулем."
								},
								{
								slug: "paragraph",
								value: "Негерметичная часть экспериментального грузового модуля подсоединена к торцу негерметичной платформы и является хранилищем для экспериментального оборудования и образцов. Экспериментальное оборудование и образцы хранятся для дальнейшего использования при проведении экспериментов на негерметичной платформе. На негерметичной части модуля могут храниться до трех образцов научного оборудования. В дополнение к этому негерметичная часть модуля вместе с результатами экспериментов и образцами может быть отстыкована от негерметичной платформы и возвращена на Землю."
								},
						]
					},
					{
						id: 3,
						content: [
							{
								slug: "paragraph",
								value: "Дистанционный манипулятор закреплен на торцевой части герметичного отсека научной лаборатории и предназначен для перемещения оборудования между экспериментальным грузовым модулем и внешней негерметичной платформой. Основная часть «руки» переносит тяжелые объекты, а для деликатной работы используется малая, съемная «рука». «Рука» оснащена видеокамерой, которая позволяет точно управлять ее движениями."
								},
								{
								slug: "paragraph",
								value: "Внешняя негерметичная платформа расположена сразу за лабораторным модулем и предназначена для размещения оборудования для проведения различных научных и технических экспериментов в условиях микрогравитации и открытого космоса, исследования Земли, связи. Экспериментальное оборудование, установленное на платформе может заменяться или убираться с платформы с помощью манипулятора, установленного на торце лабораторного модуля."
								},
						]
					},
				]
			},
		]
	},
	{
		id: 10,
		name: "Малый исследовательский модуль «Поиск»",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/MksModal/10/10_Poisk.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/MksModal/10/modal-01.png")},
					{ id: 2, image: require("../images/MksModal/10/modal-02.png")},
					{ id: 3, image: require("../images/MksModal/10/modal-03.png")},
					{ id: 4, image: require("../images/MksModal/10/modal-04.png")},
					{ id: 5, image: require("../images/MksModal/10/modal-05.png")}
				]
			},
		],
		content: [
			{
				slug: "textSlider",
				value: [
					{
						id: 1,
						content: [
							{
								slug: "heading",
								value: "Малый исследовательский модуль «Поиск»"
								},
								{
								slug: "modalInfo",
								value: {
									"symbol": 'МИМ2',
									"start": '10 ноября 2009 г',
									"finish": '12 ноября 2009 г',
								}
								},
								{
								slug: "paragraph",
								value: "Является элементом РС МКС, разработан и изготовлен в Ракетно-космической корпорации «Энергия» имени С.П. Королёва."
								},
								{
								slug: "paragraph",
								value: "Предназначен для проведения научно-прикладных исследований и экспериментов внутри и снаружи модуля, использования в качестве шлюзового отсека для выходов в открытый космос и дополнительного порта для стыковок с МКС пилотируемых кораблей типа «Союз» и автоматических грузовых кораблей типа «Прогресс». Используется для обеспечения внекорабельной деятельности в качестве отсека для шлюзования двух космонавтов, обеспечения транзитной передачи топлива от транспортных грузовых кораблей типа «Прогресс» и размещения двух базовых точек для установки внешней научной аппаратуры и грузовой стрелы."
								},
						]
					},
					{
						id: 2,
						content: [
							{
								slug: "boldText",
								value: "Запуск  осуществлен 10 ноября 2009 года. 12 ноября модуль состыковался с СМ «Звезда»."
								},
								{
								slug: "paragraph",
								value: "Для интеграции научной аппаратуры (НА) на МИМ2 снаружи имеются универсальные рабочие места (УРМ) для проведения экспериментов и разъемы для подключения к электрическим и информационным интерфейсам модуля; внутри модуля имеются электророзетки для подключения аппаратуры."
								},
								{
								slug: "paragraph",
								value: "Модуль «Поиск» имеет два стыковочных узла, расположенных по его продольной оси, – активный и пассивный.  Выходные люки расположены по правому и левому бортам. Для удобства работы космонавтов они имеют кольцевые поручни внутри и снаружи отсека. Крышки люков открываются внутрь отсека."
								},
								{
								slug: "paragraph",
								value: "Для визуально-инструментальных наблюдений используются два иллюминатора диаметром 228 мм в выходных люках."
								},
								
						]
					},
					{
						id: 3,
						content: [
							{
								slug: "paragraph",
								value: "Модуль «Поиск» создан с максимальным использованием задела по стыковочному отсеку «Пирс» и кораблю-модулю «Прогресс М-СО1», что позволило обеспечить преемственность разработки и учесть опыт эксплуатации этого отсека в составе МКС. Корпус модуля и силовой набор изготовлены из алюминиевых сплавов, трубопроводы – из коррозионностойких сталей и титановых сплавов. Снаружи корпус закрыт экранно-вакуумной теплоизоляцией."
								},
								{
								slug: "paragraph",
								value: "Через модуль «Поиск» проходят транзитные магистрали дозаправки топливом. При создании модуля «Поиск» был проведён ряд доработок по результатам эксплуатации стыковочного отсека «Пирс» в составе МКС, а также обусловленных необходимостью обеспечения возможности проведения научных исследований."
								},
						]
					},
					{
						id: 4,
						content: [
							{
								slug: "modalTechnical",
								value: {
									title: "Основные технические характеристики модуля:",
									content: [
										{id: '1', name: "Длина корпуса (по плоскостям стыковочных агрегатов)", value: "4 049 мм"},
										{id: '2', name: "Максимальный диаметр корпуса", value: "2 550 мм"},
										{id: '3', name: "Внутренний объём (по газу)", value: "12,5 м³"},
										{id: '4', name: "Количество люков для выходов в открытый космос", value: "2"},
										{id: '5', name: "Диаметр выходных люков", value: "1 000 мм"},
									]
								}
							},
						]
					},
					{
						id: 5,
						content: [
							{
								slug: "modalList",
								value: {
									title: "Основные отличия модуля «Поиск» от стыковочного отсека «Пирс»:",
									content: [
										{id: '1', name: "В состав модуля введён комплекс целевых нагрузок, предназначенный для размещения научной аппаратуры и её интеграции с бортовыми системами МКС. В структуру комплекса входят:\n\u2022  универсальное рабочее место;\n\u2022  механические адаптеры;\n\u2022  научная аппаратура;\n\u2022  кабели для подключения научной аппаратуры."},
										{id: '2', name: "Доработаны средства обеспечения газового состава с учётом необходимости вакуумирования и наддува герметичного контейнера целевой нагрузки."},
										{id: '3', name: "Дополнительно установлена мишень контроля стыковки, предназначенная для визуального контроля автоматической стыковки кораблей с модулем."},
										{id: '4', name: "Установлены два дополнительных подкоса выходного устройства."},
										{id: '5', name: "Модернизирована система бортовых измерений."},
										{id: '6', name: "Изменён состав вычислительных средств системы управления бортовой аппаратурой."},
									]
								}
							},
						]
					},
				]
			},
		]
	},
	{
		id: 11,
		name: "Жилой модуль «Транквилити»",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/MksModal/11/11_Tranquility.mp4")},
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/MksModal/11/modal-01.png")},
					{ id: 2, image: require("../images/MksModal/11/modal-02.png")}
				]
			},
		],
		content: [
			{
				slug: "textSlider",
				value: [
					{
						id: 1,
						content: [
							{
								slug: "heading",
								value: "Жилой модуль «Транквилити» (Tranquility)"
								},
								{
								slug: "modalInfo",
								value: {
									"symbol": 'NODE3',
									"start": '08 февраля 2010 г',
									"finish": '12 февраля 2010 г',
								}
								},
								{
								slug: "paragraph",
								value: "Модуль «Транквилити» (англ. Tranquility) – жилой модуль Международной космической станции. В нем находится система жизнеобеспечения, способная перерабатывать жидкие отходы в воду, пригодную для бытового использования, а также производить кислород для дыхания. Кроме этого в «Транквилити» имеется туалет и система очистки воздуха, удаляющая загрязнения из атмосферы станции и контролирующая её состав."
								},
						]
					},
					{
						id: 2,
						content: [
							{
								slug: "paragraph",
								value: "«Транквилити» имеет шесть стыковочных узлов. Одним из осевых стыковочных узлов он пристыковывается к модулю «Юнити». К нижнему стыковочному узлу «Транквилити» пристыковывается модуль панорамного обзора «Купола», предназначенный для наблюдения за поверхностью Земли, космическим пространством и работающими в открытом космосе людьми или техникой."
								},
								{
								slug: "paragraph",
								value: "В рамках бартерного соглашения с НАСА основным контрактным производителем «Транквилити» является франко-итальянская компания Thales Alenia Space. Ранее европейская сторона уже создала для МКС модули Node 2 «Гармония» и «Купола», в обмен на это НАСА взяло на себя обязательства по доставке на орбиту и введению в состав станции европейской лаборатории «Коламбус» в 2006 году."
								},
								{
								slug: "paragraph",
								value: "В «Транквилити» имеется восемь двухметровых стоек. Две из них используются для установки оборудования управления самим модулем, остальные - для системы контроля состояния МКС, а также части систем управления жизнеобеспечением станции (системы циркуляции воздуха, генерации кислорода, утилизации отходов, переработки воды и др.)."
								},
						]
					},
				]
			},
		]
	},
	{
		id: 12,
		name: "Обзорный модуль «Купола»",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/MksModal/12/12_Cupola.mp4")},
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/MksModal/12/modal-01.png")},
					{ id: 2, image: require("../images/MksModal/12/modal-02.png")}
				]
			},
		],
		content: [
			{
				slug: "textSlider",
				value: [
					{
						id: 1,
						content: [
							{
								slug: "heading",
								value: "Обзорный модуль «Купола» (Cupola)"
								},
								{
								slug: "modalInfo",
								value: {
									"symbol": 'CUPOLA',
									"start": '08 февраля 2010 г',
									"finish": '12 февраля 2010 г',
								}
								},
								{
								slug: "paragraph",
								value: "Модуль «Купола» (от итальянского слова cupola - купол) предназначен для наблюдения за поверхностью Земли, космическим пространством и работающими в открытом космосе людьми или техникой."
								},
								{
								slug: "paragraph",
								value: "Доставлен на МКС шаттлом «Индевор» (Endeavour) вместе с модулем «Транквилити». После установки «Транквилити» на модуль «Юнити», «Купола» переносится на штатное рабочее место - нижний стыковочный узел модуля «Транквилити»."
								},
						]
					},
					{
						id: 2,
						content: [
							{
								slug: "paragraph",
								value: "Модуль представляет собой панорамный обзорный купол, имеющий семь прозрачных иллюминаторов из кварцевого стекла толщиной 10 см - одного круглого в центре и шести трапециевидных вокруг него. Иллюминаторы оборудованы крышками для защиты модуля от попадания микрометеоритов и космического мусора."
								},
								{
								slug: "paragraph",
								value: "Внутри модуля установлены системы аудио- и видеоконтроля, слежения за температурным режимом станции, а также рабочие места для управления роботом-манипулятором «Канадарм2»."
								},
						]
					},
				]
			},
		]
	},
	{
		id: 13,
		name: "Малый исследовательский модуль «Рассвет»",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/MksModal/13/13_Rassvet.mp4")},
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/MksModal/13/modal-01.png")},
					{ id: 2, image: require("../images/MksModal/13/modal-02.png")},
					{ id: 3, image: require("../images/MksModal/13/modal-03.png")},
					{ id: 4, image: require("../images/MksModal/13/modal-04.png")},
					{ id: 5, image: require("../images/MksModal/13/modal-05.png")},
					{ id: 6, image: require("../images/MksModal/13/modal-06.png")},
					{ id: 7, image: require("../images/MksModal/13/modal-07.png")}
				]
			},
		],
		content: [
			{
				slug: "textSlider",
				value: [
					{
						id: 1,
						content: [
							{
								slug: "heading",
								value: "Малый исследовательский модуль «Рассвет»"
								},
								{
								slug: "modalInfo",
								value: {
									"symbol": 'МИМ1',
									"start": '14 мая 2010 г',
									"finish": '18 мая 2010 г',
								}
								},
								{
								slug: "paragraph",
								value: "Малый исследовательский модуль № 1 (МИМ-1) «Рассвет» является элементом российского сегмента Международной космической станции. Он разработан и изготовлен в Ракетно-космической корпорации «Энергия» имени С.П. Королёва."
								},
								{
								slug: "paragraph",
								value: "Модуль «Рассвет» позволил увеличить рабочий объем, выделяемый для работы с научной аппаратурой."
								},
								{
								slug: "paragraph",
								value: "Внутри модуля «Рассвет» имеются поручни, обеспечивающие фиксацию оператора.	Для интеграции научной аппаратуры на МИМ2 имеются пять универсальных рабочих мест (УРМ) внутри гермоотсека."
								},
						]
					},
					{
						id: 2,
						content: [
								{
								slug: "boldText",
								value: "Запуск  осуществлен 14 мая 2010 года. 18 мая был пристыкован к надирному стыковочному узлу блока «Заря»."
								},
								{
									slug: "modalList",
									value: {
										title: "Предназначен для:",
										content: [
											{id: '1', name: "организации порта для стыковки транспортных кораблей типа «Союз» и «Прогресс»;"},
											{id: '2', name: "обеспечения транзитной передачи топлива от транспортных грузовых кораблей типа «Прогресс»;"},
											{id: '3', name: "предоставления зон хранения грузов, организации рабочих мест и размещения научной аппаратуры (НА) для проведения экспериментов."},
										]
									}
								},
						]
					},
					{
						id: 3,
						content: [
							{
								slug: "paragraph",
								value: "В гермоотсеке модуля организованы пять универсальных рабочих мест.Четыре из них оснащены целевым оборудованием: перчаточным боксом, универсальным низкотемпературным биотехнологическим термостатом, универсальным высокотемпературным биотехнологическим термостатом, виброзащитной платформой. Пятое рабочее место оборудовано адаптерами для установки научной аппаратуры (специальными выдвижными модуль-полками)."
								},
								{
									slug: "modalTechnical",
									value: {
										title: "Основные технические характеристики модуля:",
										content: [
											{id: '1', name: "Длина корпуса (по плоскостям стыковочных агрегатов)", value: "6 000 мм"},
											{id: '2', name: "Максимальный диаметр корпуса (без навесного оборудования)", value: "2 200 мм"},
											{id: '3', name: "Внутренний объём (по газу)", value: "17,4 м³"},
											{id: '4', name: "в том числе жилой объём", value: "5,8 м³"},
											{id: '5', name: "Объём для хранения грузов", value: "4,4 м³"},
											{id: '6', name: "Объём для размещения научной аппаратуры", value: "3,0 м³"},
											{id: '7', name: "Стартовая масса модуля", value: "8 015 кг"},
											{id: '8', name: "Масса доставляемых грузов и оборудования", value: "2 940 кг"},
											{id: '9', name: "в том числе в гермоотсеке", value: "1 392 кг"},
										]
									}
								},
						]
					},
					{
						id: 4,
						content: [
							{
								slug: "modalList",
								value: {
									title: "В состав бортовых систем модуля МИМ1 «Рассвет» входят:",
									content: [
										{id: '1', name: "система стыковки активная;"},
										{id: '2', name: "система стыковки пассивная;"},
										{id: '3', name: "система управления бортовой аппаратурой;"},
										{id: '4', name: "система электроснабжения;"},
										{id: '5', name: "система обеспечения теплового режима;"},
										{id: '6', name: "средства обеспечения газового состава;"},
										{id: '7', name: "средства противопожарной защиты;"},
										{id: '8', name: "система бортовых измерений;"},
										{id: '9', name: "средства управления движением и навигации;"},
										{id: '10', name: "телевизионная система;"},
										{id: '11', name: "средства обеспечения дозаправки топлива;"},
										{id: '12', name: "система телефонной связи;"},
										{id: '13', name: "система управления СОТР;"},
										{id: '14', name: "комплекс целевых нагрузок."},
									]
								}
							},
						]
					},
				]
			},
		]
	},
	{
		id: 14,
		name: "Многофункциональный модуль «Леонардо»",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/MksModal/14/14_Leonardo.mp4")},
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/MksModal/14/modal-01.png")},
					{ id: 2, image: require("../images/MksModal/14/modal-02.png")}
				]
			},
		],
		content: [
			{
				slug: "textSlider",
				value: [
					{
						id: 1,
						content: [
							{
								slug: "heading",
								value: "Постоянный многоцелевой модуль «Леонардо» (Leonardo)"
								},
								{
								slug: "modalInfo",
								value: {
									"symbol": 'РММ',
									"start": '26 февраля 2011 г',
									"finish": '01 марта 2011 г',
								}
								},
								{
								slug: "paragraph",
								value: "Постоянный многоцелевой модуль PMM (Permanent Multipurpose Module) «Леонардо» (англ. Leonardo) доставлен на МКС шаттлом «Дискавери» (Discovery) STS-133 26 февраля 2011 года и 01 марта с помощью манипулятора SSRMS установлен на надирный порт модуля «Юнити»."
								},
						]
					},
					{
						id: 2,
						content: [
							{
								slug: "paragraph",
								value: "Изначально модуль «Леонардо» создавался Итальянским космическим агентством как многоцелевой герметичный модуль материально-технического снабжения MPLM (Multi-Purpose Logistic Module) и в этом качестве совершил семь полётов на МКС в грузовых отсеках шаттлов «Дискавери» и «Атлантис». После оснащения модуля необходимыми средствами, позволяющими ему постоянно находиться в составе станции (системами энергопитания, жизнеобеспечения, терморегулирования, пожаротушения, передачи команд и обработки данных, а также микрометеоритной защитой), он был установлен на МКС и стал дополнительным помещением американского сегмента."
								},
								{
								slug: "paragraph",
								value: "Основой модуля «Леонардо» является герметичный корпус цилиндрической формы с двумя коническими днищами, на переднем днище вокруг люка установлен пассивный стыковочный узел."
								},
						]
					},
				]
			},
		]
	},
	{
		id: 15,
		name: "Научно-энергетический модуль",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/MksModal/15/15_NEM.mp4")},
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/MksModal/15/modal-01.png")},
					{ id: 2, image: require("../images/MksModal/15/modal-02.png")},
					{ id: 3, image: require("../images/MksModal/15/modal-03.png")},
					{ id: 4, image: require("../images/MksModal/15/modal-04.png")},
					{ id: 5, image: require("../images/MksModal/15/modal-05.png")},
					{ id: 6, image: require("../images/MksModal/15/modal-06.png")}
				]
			},
		],
		content: [
			{
				slug: "textSlider",
				value: [
					{
						id: 1,
						content: [
							{
								slug: "heading",
								value: "Научно-энергетический модуль"
								},
								{
								slug: "modalInfo",
								value: {
									"symbol": 'НЭМ или НЭМ1'
								}
								},
								{
								slug: "paragraph",
								value: "Базовый модуль российской национальной орбитальной космической станции (РОСС); изначально — как один из модулей для МКС. Предназначен для проведения научных экспериментов, обеспечения национальной станции электроэнергией и обеспечения станции дополнительными средствами управления."
								},
								{
								slug: "boldText",
								value: "Техническое оснащение:"
								},
								{
								slug: "paragraph",
								value: "В комплексе использована цифровая система управления разработки РКК «Энергия», бортовая центральная вычислительная машина от НИИ «Аргон», аппаратура относительной навигации систем ГЛОНАСС и GPS, широкополосная система связи с использованием системы ретрансляции «Луч»."
								},
						]
					},
					{
						id: 2,
						content: [
							{
								slug: "modalTechnical",
								value: {
									title: "Основные технические характеристики модуля:",
									content: [
										{id: '1', name: "Объем модуля", value: "92 м³"},
										{id: '2', name: "Объем для научной аппаратуры и грузов", value: "15 м³"},
										{id: '3', name: "Среднегодовая мощность", value: "18 кВт"},
										{id: '4', name: "Мощность электроэнергии номинальным напряжением 120 В для РС МКС", value: "12 кВт"},
										{id: '5', name: "Установленная мощность", value: "около 50 кВт"},
										{id: '6', name: "Доступная мощность для потребителя", value: "около 20 кВт/сутки"},
										{id: '7', name: "КПД солнечных батарей модуля", value: "28%"},
										
									]
								}
							},
							{
							slug: "paragraph",
							value: "Вследствие того, что топливные баки модуля «Наука» будут одноразовыми, удержание МКС на орбите будет осуществляться модулем НЭМ. На нем будут и дополнительные баки, и двигатели для удержания станции на орбите."
							},
						]
					},
				]
			},
		]
	},
	{
		id: 16,
		name: "Многоцелевой исследовательский модуль «Наука»",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/MksModal/16/16_Nauka.mp4")},
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/MksModal/16/modal-01.png")},
					{ id: 2, image: require("../images/MksModal/16/modal-02.png")},
					{ id: 3, image: require("../images/MksModal/16/modal-03.png")},
					{ id: 4, image: require("../images/MksModal/16/modal-04.png")},
					{ id: 5, image: require("../images/MksModal/16/modal-05.png")},
					{ id: 6, image: require("../images/MksModal/16/modal-06.png")},
				]
			},
		],
		content: [
			{
				slug: "textSlider",
				value: [
					{
						id: 1,
						content: [
							{
								slug: "heading",
								value: "«Наука» - многоцелевой лабораторный модуль Российского сегмента МКС"
								},
								{
								slug: "paragraph",
								value: "Он создавался кооперацией предприятий в целях реализации программы научных экспериментов и расширения функциональных возможностей российского сегмента МКС."
								},
								{
								slug: "paragraph",
								value: "Новый российский модуль предназначен для наращивания технических и эксплуатационных возможностей российского сегмента Международной космической станции. Он будет обеспечивать возможность реализации программы научных исследований в интересах фундаментальной науки и социальной сферы, и в том числе развитие и наращивание ресурсов российского сегмента МКС."
								},
								{
								slug: "paragraph",
								value: "Модуль «Наука» создан на основе Функционально-грузового блока «Заря», который уже более 22 лет работает на околоземной орбите, с использованием технологий и опыта разработки транспортного корабля снабжения пилотируемых научных станций «Салют» и модулей дооснащения орбитального комплекса «Мир». Он состоит из приборно-герметичного отсека и сферического гермоадаптера, разделенных герметичной переборкой с люком."
								},
						]
					},
					{
						id: 2,
						content: [
							{
								slug: "boldText",
								value: "Пуск ракеты космического назначения «Протон-М» с модулем «Наука»"
								},
								{
								slug: "paragraph",
								value: "был осуществлен с пусковой установки № 39 стартовой площадки № 200 космодрома Байконур 21 июля 2021 года в 17:58:25 по московскому времени."
								},
								{
									slug: "modalTechnical",
									value: {
										content: [
											{id: '1', name: "Высота апогея", value: "375,5 км"},
											{id: '2', name: "Высота перигея", value: "199,0 км"},
											{id: '3', name: "Наклонение", value: "51,6 град"},
											{id: '4', name: "Период обращения", value: "89,96 мин"},
										]
									}
								},
								{
									slug: "paragraph",
									value: "Дальнейшее сближение с Международной космической станцией осуществлялось двигателями модуля «Наука». Продолжительность выведения модуля в зону стыковки с МКС составила 8 суток."
								},
								{
									slug: "boldText",
									value: "Стыковка состоялась 29 июля 2021 года в 16:29 по московскому времени."
								},
						]
					},
					{
						id: 3,
						content: [
							{
								slug: "modalList",
								value: {
									title: "Назначение:",
									content: [
										{id: '1', name: "обеспечение стыковок транспортных грузовых кораблей «Прогресс МС», транспортных пилотируемых кораблей «Союз МС» и узлового модуля «Причал»;"},
										{id: '2', name: "управление Международной космической станции по крену;"},
										{id: '3', name: "прием топлива от грузовых кораблей «Прогресс МС», его хранение и передача в баки модуля «Звезда» для выполнения динамических операций — коррекции орбиты, ориентации и стабилизации МКС;"},
										{id: '4', name: "хранение доставляемых в интересах российского сегмента МКС грузов;"},
										{id: '5', name: "обеспечение функционирования европейского манипулятора European Robotic Arm (ERA);"},
										{id: '6', name: "функционирование комплекса целевых нагрузок для выполнения программы научно-прикладных исследований в условиях повышенной комфортности экипажа;"},
										{id: '7', name: "производство кислорода для обеспечения потребностей экипажа до шести человек;"},
										{id: '8', name: "функционирование шлюзовой камеры для работы с целевыми нагрузками, в том числе с использованием роботизированного манипулятора ERA;"},
										{id: '9', name: "функционирование бортовой мастерской и каюты для третьего члена экипажа российского сегмента МКС, а также обеспечение работы ассенизационно-санитарного устройства с системой регенерации воды из урины."},
									]
								}
							},
						]
					},
					{
						id: 4,
						content: [
							{
								slug: "boldText",
								value: "В модуле «Наука» размещаются :"
								},
								{
								slug: "paragraph",
								value: "Бортовая мастерская, каюта для третьего члена экипажа РС МКС, а также ассенизационно-санитарное устройство с системой регенерации воды из урины."
								},
								{
								slug: "modalList",
								value: {
									title: "МЛМ обеспечивает для интеграции научной аппаратуры (НА):",
									content: [
										{id: '1', name: "установку полезных нагрузок внутри гермоотсека МЛМ – 9 универсальных рабочих мест (УРМ), в том числе рабочее место с иллюминатором для установки оптических блоков научной аппаратуры;"},
										{id: '2', name: "установку вне гермоотсека МЛМ не менее 13 целевых нагрузок (с учетом двух платформ с адаптерами полезной нагрузки активными);"},
										{id: '3', name: "механические и вакуумные интерфейсы;"},
										{id: '4', name: "интерфейсы электропитания и термостатирования НА;"},
										{id: '5', name: "командно-информационные интерфейсы."},
									]
								}
							},
							{
								slug: "boldText",
								value: "В модуле размещено целевое оборудование: перчаточный бокс, термостаты и виброзащитная платформа."
								},
						]
					},
					{
						id: 5,
						content: [
							{
								slug: "modalTechnical",
								value: {
									title: "Основные технические характеристики модуля:",
									content: [
										{id: '1', name: "Масса на орбите", value: "20 350 кг"},
										{id: '2', name: "Длина по корпусу", value: "13,12 м"},
										{id: '3', name: "Максимальный диаметр", value: "4,25 м"},
										{id: '4', name: "Объём герметичных отсеков", value: "70 м³"},
										{id: '5', name: "Площаль фотоэлектрических элементов", value: "56 м2"},
										{id: '6', name: "Мощность электроснабжения", value: "2,5 кВт"},
										{id: '7', name: "Расчетная длительность функционирования на орбите", value: "15 лет"},
										{id: '8', name: "Высота рабочей орбиты", value: "410-460 км"},
									]
								}
							},
							{
								slug: "modalList",
								value: {
									title: "Расширение возможностей российского сегмента МКС как научной лаборатории:",
									content: [
										{id: '1', name: "16 рабочих мест снаружи изделия;"},
										{id: '2', name: "14 рабочих мест внутри изделия;"},
										{id: '3', name: "объем для научного оборудования 6 м3;"},
										{id: '4', name: "объем для грузов 4,9 м3."},
									]
								}
							},
						]
					},
					{
						id: 6,
						content: [
							{
								slug: "boldText",
								value: "Программа лётных испытаний модуля «Наука»:"
								},
								{
									slug: "modalList",
									value: {
										title: "Этапы летных испытаний:",
										content: [
											{id: '1', name: "выведение, автономный полет и стыковка модуля к российскому сегменту станции;"},
											{id: '2', name: "интеграция модуля «Наука» в состав российского сегмента МКС, в том числе установка шлюзовой камеры, радиационного теплообменника дополнительного, средств крепления крупногабаритных объектов;"},
											{id: '3', name: "проверка работоспособности систем модуля и интерфейсов, обеспечивающих связь модуля с российским сегментом МКС;"},
											{id: '4', name: "дооснащение модуля «Наука» доставляемым оборудованием и научной аппаратурой;"},
											{id: '5', name: "отработка режимов модуля, полетных операций и мер по выходу из нештатных ситуаций, предусмотренных ПЛИ."},
										]
									}
								},
								{
								slug: "paragraph",
								value: "Продолжительность летных испытаний — 12 месяцев с момента пуска с космодрома Байконур."
								},
						]
					},
				]
			},
		]
	},
	{
		id: 17,
		name: "Узловой модуль «Причал»",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/MksModal/17/17_Prichal.mp4")},
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/MksModal/17/modal-01.png")},
					{ id: 2, image: require("../images/MksModal/17/modal-02.png")},
					{ id: 3, image: require("../images/MksModal/17/modal-03.png")},
					{ id: 4, image: require("../images/MksModal/17/modal-04.png")},
					{ id: 5, image: require("../images/MksModal/17/modal-05.png")},
					{ id: 6, image: require("../images/MksModal/17/modal-06.png")},
					{ id: 7, image: require("../images/MksModal/17/modal-07.png")}
				]
			},
		],
		content: [
			{
				slug: "textSlider",
				value: [
					{
						id: 1,
						content: [
							{
								slug: "heading",
								value: "Узловой модуль «Причал»"
								},
								{
								slug: "modalInfo",
								value: {
									"symbol": 'УМ',
									"start": '24 ноября 2021 г',
									"finish": '26 ноября 2021 г',
								}
								},
								{
								slug: "paragraph",
								value: "Узловой модуль «Причал» предназначен для наращивания технических и эксплуатационных возможностей российского сегмента Международной космической станции. Модуль пристыкован к надирному узлу многоцелевого лабораторного модуля «Наука»."
								},
								{
								slug: "paragraph",
								value: "Дальнейшее развитие российского сегмента МКС обеспечивается за счет стыковки к узловому модулю транспортных систем, в том числе и перспективных."
								},
								{
								slug: "paragraph",
								value: "Разработчиком узлового модуля является Ракетно-космическая корпорация «Энергия» имени С.П. Королева (входит в состав Госкорпорации «Роскосмос»)."
								},
						]
					},
					{
						id: 2,
						content: [
							{
								slug: "boldText",
								value: "Модуль предназначен для решения следующих задач:"
								},
								{
									slug: "modalList",
									value: {
										title: "В составе транспортного грузового корабля-модуля «Прогресс М-УМ»:",
										content: [
											{id: '1', name: "выведение и полет с орбиты выведения до монтажной орбиты;"},
											{id: '2', name: "обеспечение сближения и стыковки в автоматическом режиме (телеоператорный режим управления — резервный режим) к многоцелевому лабораторному модулю «Наука»;"},
											{id: '3', name: "обеспечение функционирования приборно-агрегатного отсека корабля в составе российского сегмента Международной космической станции, подготовки к отделению и отделения его от узлового модуля;"},
											{id: '4', name: "доставка на российский сегмент Международной космической станции грузов общей массой не более 700 кг."},
										]
									}
								},
						]
					},
					{
						id: 3,
						content: [
							{
								slug: "modalList",
								value: {
									title: "В составе российского сегмента Международной космической станции:",
									content: [
										{id: '1', name: "обеспечение возможности стыковки к узловому модулю «Причал» до пяти объектов;"},
										{id: '2', name: "обеспечение возможности перестыковки изделий, оснащенных манипулятором-перестыковщиком, с осевого порта на боковой и обратно;"},
										{id: '3', name: "обеспечение и контроль совместно со средствами российского сегмента МКС, необходимых условий жизнедеятельности экипажа в гермоотсеке узлового модуля «Причал»;"},
										{id: '4', name: "предоставление электрических, гидравлических и механических реконфигурируемых интерфейсов и интерфейсов газообмена между пристыкованными к узловому модулю «Причал» изделиями;"},
										{id: '5', name: "транзитная передача топлива от транспортных грузовых кораблей через магистрали дозаправки и гидроразъёмы стыковочного агрегата в модуль «Наука» и другие модули (впоследствии), а также обратно в грузовой корабль с обеспечением дренажа перед отделением корабля;"},
										{id: '6', name: "трансляция цепей подзаряда и объединенного питания с модулем «Наука» на пристыкованные объекты."},
									]
								}
							},
						]
					},
					{
						id: 4,
						content: [
							{
								slug: "modalTechnical",
								value: {
									title: "Основные технические характеристики модуля:",
									content: [
										{id: '1', name: "Стартовая масса модуля", value: "4 650 кг"},
										{id: '2', name: "Диаметр сферического корпуса", value: "3 300 мм"},
										{id: '3', name: "Количество стыковочных агрегатов", value: "6"},
										{id: '4', name: "Масса доставляемых грузов", value: "не более 700 кг"},
										{id: '5', name: "Гермообъем", value: "19 м³"},
										{id: '6', name: "Максимальное электропотребление бортовых систем модуля при полёте в составе корабля «Прогресс М-УМ»", value: "700 Вт"},
										{id: '7', name: "Среднесуточное электропотребление бортовых систем модуля при полёте в составе российского сегмента МКС", value: "500 Вт"},
									]
								}
							},
							{
							slug: "paragraph",
							value: "Узловой модуль «Причал» представляет собой сферический герметичный отсек с размещенными внутри и снаружи него составными частями, обеспечивающими выполнение его задач."
							},
						]
					},
					{
						id: 5,
						content: [
							{
								slug: "paragraph",
								value: "В состав узлового модуля входят корпус и комплекс бортовых систем, включающий систему управления бортовой аппаратурой, радиотехнические средства, систему обеспечения теплового режима, средства обеспечения газового состава, средства управления движением и навигации, транзитные магистрали дозаправки топливом, систему стыковки активная гибридная, систему стыковки пассивную гибридную, средства перестыковки. На модуле применена пассивная система обеспечения теплового режима на тепловых трубах с использованием автоматических электронагревателей в паре с электронными переключателями."
								},
								{
								slug: "paragraph",
								value: "На внешней поверхности корпуса размещены активный гибридный агрегат стыковки (АСА-Г), агрегат стыковки пассивный комбинированный (АСП-К) и четыре агрегата стыковки пассивных гибридных боковых (два АСП-ГБ1 и два АСП-ГБ2)."
								},
								{
								slug: "paragraph",
								value: "В целях обеспечения стыковки к узловому модулю на его внешней поверхности по оси стыковочного агрегата АСП-К размещены антенны пассивной аппаратуры системы сближения «Курс-П», мишени стыковки и мишени контроля, блок телекамеры из состава радиотехнических средств, а также такелажные элементы средств перестыковки."
								},
						]
					},
					{
						id: 6,
						content: [
							{
								slug: "paragraph",
								value: "Для обеспечения функционирования узлового модуля «Причал» в составе транспортного грузового корабля-модуля «Прогресс М-УМ» на его внешней поверхности со стороны активного агрегата стыковки установлены телекамера контроля стыковки и передающая антенна системы «Клест», антенны активной системы сближения «Курс-НА»."
								},
								{
								slug: "paragraph",
								value: "В целях обеспечения передачи компонентов топлива на внешней поверхности размещены трубопроводы и блоки клапанов транзитных магистралей дозаправки топливом. Для обеспечения фиксации космонавта (оператора) при выполнении внекорабельной деятельности на внешней поверхности также установлены поручни средств фиксации оператора. Корпус и расположенные на его поверхности кабели, трубопроводы и агрегаты закрыты листами микрометеороидной защиты."
								},
						]
					},
					{
						id: 7,
						content: [
							{
								slug: "paragraph",
								value: "Внутренний объем сферического корпуса разделен панелями интерьера на две зоны — приборную и обитаемую. Панели интерьера со средствами фиксации оператора устанавливаются на рамы силового каркаса приборной зоны. Силовой каркас приборной зоны, а также посадочные места из состава корпуса являются конструктивной базой для установки оборудования внутри корпуса, в том числе доставляемого."
								},
								{
								slug: "paragraph",
								value: "Места внутри корпуса, не закрытые интерьером, закрываются фальшь-панелями, крепление которых позволяет осуществить, при необходимости, их замену на этапе целевого использования. На внутренней поверхности корпуса установлены электронагреватели с электронными переключателями СОТР, обеспечивающие термостатирование корпуса."
								},
						]
					},
				]
			},
		]
	},
];

const scienceAboutInfo = {
	id: 1,
	name: "Многоцелевой исследовательский модуль «Наука»",
	video: [
		{
			slug: "videoBlock",
			value: { id: 1, video: require("../images/ScienceModal/main/16_Nauka.mp4")},
			
		},
	],
	slider: [
		{
			slug: "modalSlider",
			value: [
				{ id: 1, image: require("../images/ScienceModal/main/modal-01.png")},
				{ id: 2, image: require("../images/ScienceModal/main/modal-02.png")},
				{ id: 3, image: require("../images/ScienceModal/main/modal-03.png")},
				{ id: 4, image: require("../images/ScienceModal/main/modal-04.png")},
				{ id: 5, image: require("../images/ScienceModal/main/modal-05.png")},
				{ id: 6, image: require("../images/ScienceModal/main/modal-06.png")},
			]
		},
	],
	content: [
		{
			slug: "textSlider",
			value: [
				{
					id: 1,
					content: [
						{
							slug: "heading",
							value: "«Наука» - многоцелевой лабораторный модуль Российского сегмента МКС"
							},
							{
							slug: "paragraph",
							value: "Он создавался кооперацией предприятий в целях реализации программы научных экспериментов и расширения функциональных возможностей российского сегмента МКС."
							},
							{
							slug: "paragraph",
							value: "Новый российский модуль предназначен для наращивания технических и эксплуатационных возможностей российского сегмента Международной космической станции. Он будет обеспечивать возможность реализации программы научных исследований в интересах фундаментальной науки и социальной сферы, и в том числе развитие и наращивание ресурсов российского сегмента МКС."
							},
							{
							slug: "paragraph",
							value: "Модуль «Наука» создан на основе Функционально-грузового блока «Заря», который уже более 22 лет работает на околоземной орбите, с использованием технологий и опыта разработки транспортного корабля снабжения пилотируемых научных станций «Салют» и модулей дооснащения орбитального комплекса «Мир». Он состоит из приборно-герметичного отсека и сферического гермоадаптера, разделенных герметичной переборкой с люком."
							},
					]
				},
				{
					id: 2,
					content: [
						{
							slug: "boldText",
							value: "Пуск ракеты космического назначения «Протон-М» с модулем «Наука»"
							},
							{
							slug: "paragraph",
							value: "был осуществлен с пусковой установки № 39 стартовой площадки № 200 космодрома Байконур 21 июля 2021 года в 17:58:25 по московскому времени.абораторный модуль российского сегмента МКС."
							},
							{
								slug: "modalTechnical",
								value: {
									content: [
										{id: '1', name: "Высота апогея", value: "375,5 км"},
										{id: '2', name: "Высота перигея", value: "199,0 км"},
										{id: '3', name: "Наклонение", value: "51,6 град"},
										{id: '4', name: "Период обращения", value: "89,96 мин"},
									]
								}
							},
							{
								slug: "paragraph",
								value: "Дальнейшее сближение с Международной космической станцией осуществлялось двигателями модуля «Наука». Продолжительность выведения модуля в зону стыковки с МКС составила 8 суток."
							},
							{
								slug: "boldText",
								value: "Стыковка состоялась 29 июля 2021 года в 16:29 по московскому времени."
							},
					]
				},
				{
					id: 3,
					content: [
						{
							slug: "modalList",
							value: {
								title: "Назначение:",
								content: [
									{id: '1', name: "обеспечение стыковок транспортных грузовых кораблей «Прогресс МС», транспортных пилотируемых кораблей «Союз МС» и узлового модуля «Причал»;"},
									{id: '2', name: "управление Международной космической станции по крену;"},
									{id: '3', name: "прием топлива от грузовых кораблей «Прогресс МС», его хранение и передача в баки модуля «Звезда» для выполнения динамических операций — коррекции орбиты, ориентации и стабилизации МКС;"},
									{id: '4', name: "хранение доставляемых в интересах российского сегмента МКС грузов;"},
									{id: '5', name: "обеспечение функционирования европейского манипулятора ERA;"},
									{id: '6', name: "функционирование комплекса целевых нагрузок для выполнения программы научно-прикладных исследований в условиях повышенной комфортности экипажа;"},
									{id: '7', name: "производство кислорода для обеспечения потребностей экипажа до шести человек;"},
									{id: '8', name: "функционирование шлюзовой камеры для работы с целевыми нагрузками, в том числе с использованием роботизированного манипулятора European Robotic Arm;"},
									{id: '9', name: "функционирование бортовой мастерской и каюты для третьего члена экипажа российского сегмента МКС, а также обеспечение работы ассенизационно-санитарного устройства с системой регенерации воды из урины."},
								]
							}
						},
					]
				},
				{
					id: 4,
					content: [
						{
							slug: "boldText",
							value: "В модуле «Наука» размещаются :"
							},
							{
							slug: "paragraph",
							value: "Бортовая мастерская, каюта для третьего члена экипажа РС МКС, а также ассенизационно-санитарное устройство с системой регенерации воды из урины."
							},
							{
							slug: "modalList",
							value: {
								title: "МЛМ обеспечивает для интеграции научной аппаратуры (НА):",
								content: [
									{id: '1', name: "установку полезных нагрузок внутри гермоотсека МЛМ – 9 универсальных рабочих мест (УРМ), в том числе рабочее место с иллюминатором для установки оптических блоков научной аппаратуры;"},
									{id: '2', name: "установку вне гермоотсека МЛМ не менее 13 целевых нагрузок (с учетом двух платформ с адаптерами полезной нагрузки активными);"},
									{id: '3', name: "механические и вакуумные интерфейсы;"},
									{id: '4', name: "интерфейсы электропитания и термостатирования НА;"},
									{id: '5', name: "командно-информационные интерфейсы."},
								]
							}
						},
						{
							slug: "boldText",
							value: "В модуле размещено целевое оборудование: перчаточный бокс, термостаты и виброзащитная платформа."
							},
					]
				},
				{
					id: 5,
					content: [
						{
							slug: "modalTechnical",
							value: {
								title: "Основные технические характеристики модуля:",
								content: [
									{id: '1', name: "Масса на орбите", value: "20 350 кг"},
									{id: '2', name: "Длина по корпусу", value: "13,12 м"},
									{id: '3', name: "Максимальный диаметр", value: "4,25 м"},
									{id: '4', name: "Объём герметичных отсеков", value: "70 м³"},
									{id: '5', name: "Площаль фотоэлектрических элементов", value: "56 м2"},
									{id: '6', name: "Мощность электроснабжения", value: "2,5 кВт"},
									{id: '7', name: "Расчетная длительность функционирования на орбите", value: "15 лет"},
									{id: '8', name: "Высота рабочей орбиты", value: "410-460 км"},
								]
							}
						},
						{
							slug: "modalList",
							value: {
								title: "Расширение возможностей российского сегмента МКС как научной лаборатории:",
								content: [
									{id: '1', name: "16 рабочих мест снаружи изделия;"},
									{id: '2', name: "14 рабочих мест внутри изделия;"},
									{id: '3', name: "объем для научного оборудования 6 м3;"},
									{id: '4', name: "объем для грузов 4,9 м3."},
								]
							}
						},
					]
				},
				{
					id: 6,
					content: [
						{
							slug: "boldText",
							value: "Программа лётных испытаний модуля «Наука»:"
							},
							{
								slug: "modalList",
								value: {
									title: "Этапы летных испытаний:",
									content: [
										{id: '1', name: "выведение, автономный полет и стыковка модуля к российскому сегменту станции;"},
										{id: '2', name: "интеграция модуля «Наука» в состав российского сегмента МКС, в том числе установка шлюзовой камеры, радиационного теплообменника дополнительного, средств крепления крупногабаритных объектов;"},
										{id: '3', name: "проверка работоспособности систем модуля и интерфейсов, обеспечивающих связь модуля с российским сегментом МКС;"},
										{id: '4', name: "дооснащение модуля «Наука» доставляемым оборудованием и научной аппаратурой;"},
										{id: '5', name: "отработка режимов модуля, полетных операций и мер по выходу из нештатных ситуаций, предусмотренных ПЛИ."},
									]
								}
							},
							{
							slug: "paragraph",
							value: "Продолжительность летных испытаний — 12 месяцев с момента пуска с космодрома Байконур."
							},
					]
				},
			]
		},
	]
}

const scienceStructure = [
	{
		id: 1,
		name: "Пассивный стыковочный агрегат",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/ScienceModal/main/16_Nauka.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/ScienceModal/1/modal-01.png")},
					{ id: 2, image: require("../images/ScienceModal/1/modal-02.png")}
				]
			},
		],
		content: [
			{
			slug: "heading",
			value: "Пассивный стыковочный аппарат"
			},
			{
			slug: "paragraph",
			value: "Пассивный стыковочный агрегат использовался для стыковки УМ к МЛМ и образования жесткого механического соединения модулей и герметичного тоннеля между УМ и МЛМ, соединение электрических интерфейсов и гидравлических магистралей модулей."
			},
		]
	},
	{
		id: 2,
		name: "Антенны Курс-П",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/ScienceModal/main/16_Nauka.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/ScienceModal/2/modal-01.png")},
					{ id: 2, image: require("../images/ScienceModal/2/modal-02.png")}
				]
			},
		],
		content: [
			{
			slug: "heading",
			value: "Антенны Курс-П"
			},
			{
			slug: "paragraph",
			value: "Антенны Курс-П использовались при стыковке УМ к МЛМ для работы системы стыковки Курс."
			},
		]
	},
	{
		id: 3,
		name: "Иллюминатор",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/ScienceModal/main/16_Nauka.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/ScienceModal/3/modal-01.png")},
					{ id: 2, image: require("../images/ScienceModal/3/modal-02.png")}
				]
			},
		],
		content: [
			{
			slug: "heading",
			value: "Иллюминатор"
			},
			{
			slug: "paragraph",
			value: "Иллюминатор МЛМ предназначен для наблюдения Земли, горизонта Земли и части космического пространства. Когда нет наблюдений, для защиты от микрометеоритов закрывается снаружи крышкой."
			},
			{
			slug: "paragraph",
			value: "Иллюминатор имеет диаметр 426 мм. и для надёжности состоит из двух стёкол толщиной 14 мм."
			},
		]
	},
	{
		id: 4,
		name: "Шлюзовая камера (ШК)",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/ScienceModal/main/16_Nauka.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/ScienceModal/4/modal-01.png")},
					{ id: 2, image: require("../images/ScienceModal/4/modal-02.png")}
				]
			},
		],
		content: [
			{
			slug: "heading",
			value: "Шлюзовая камера (ШК)"
			},
			{
			slug: "paragraph",
			value: "Шлюзовая камера предназначена для автоматизации выноса грузов из станции на внешнюю поверхность совместно с манипулятором ERA для уменьшения количества выхода космонавтов в космос. ШК доставлена и хранилась снаружи МИМ1, установлена на МЛМ манипулятором ERA."
			},
			{
			slug: "paragraph",
			value: "ШК имеет объём 2,1 м3, длину 1,2 м и диаметр 0,5 м."
			},
		]
	},
	{
		id: 5,
		name: "Средства крепления крупногабаритных объектов (СККО)",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/ScienceModal/main/16_Nauka.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/ScienceModal/5/modal-01.png")},
					{ id: 2, image: require("../images/ScienceModal/5/modal-02.png")}
				]
			},
		],
		content: [
			{
			slug: "heading",
			value: "Средства крепления крупногабаритных объектов (СККО)"
			},
			{
			slug: "paragraph",
			value: "СККО предназначено для увеличения на 3 шт. универсальных рабочих мест (УРМ) научной аппаратуры на внешней поверхности МЛМ. СККО устанавливается космонавтами во время выхода в космос (ВКД)."
			},
		]
	},
	{
		id: 6,
		name: "Платформа для установки научного оборудования",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/ScienceModal/main/16_Nauka.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/ScienceModal/6/modal-01.png")},
					{ id: 2, image: require("../images/ScienceModal/6/modal-02.png")}
				]
			},
		],
		content: [
			{
			slug: "heading",
			value: "Платформа для установки научного оборудования"
			},
			{
			slug: "paragraph",
			value: "Платформа с адаптерами для установки научного оборудования позволяет устанавливать на УРМ три блока научных аппаратур."
			},
		]
	},
	{
		id: 7,
		name: "Переносное рабочее место (ПРМ)",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/ScienceModal/main/16_Nauka.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/ScienceModal/7/modal-01.png")},
					{ id: 2, image: require("../images/ScienceModal/7/modal-02.png")}
				]
			},
		],
		content: [
			{
			slug: "heading",
			value: "Переносное рабочее место"
			},
			{
			slug: "paragraph",
			value: "Переносное рабочее место предназначено для фиксации космонавта при проведении работ на внешней поверхности модуля МЛМ при проведении работ с манипулятором ERA."
			},
		]
	},
	{
		id: 8,
		name: "Блоки двигателей",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/ScienceModal/main/16_Nauka.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/ScienceModal/8/modal-01.png")},
					{ id: 2, image: require("../images/ScienceModal/8/modal-02.png")}
				]
			},
		],
		content: [
			{
			slug: "heading",
			value: "Блоки двигателей"
			},
			{
				slug: "modalList",
				value: {
					title: "На модуле «Наука» установлены три типа двигателей:",
					content: [
						{id: '1', name: "двигатели коррекции и сближения (ДКС) — главные двигатели, 2 двигателя КРД-442 с номинальной тягой 417 килограмм-сил (4,09 килоньютона) каждый, призваны обеспечивать коррекцию орбиты на этапе автономного полёта к МКС. Двигатели могут включаться до 100 раз; их ресурс составляет 2600 с."},
						{id: '2', name: "двигатели причаливания и стабилизации (ДПС) — 24 двигателя 11Д458 с тягой 40 килограмм-сил (392 ньютона);"},
						{id: '3', name: "двигатели точной стабилизации (ДТС) — 16 двигателей ДТС (17Д58Э) с тягой 1,36 килограмм-сил (13,3 ньютона), предназначены для точной стабилизации при стыковке. Максимальное время огневой работы — 180 000 с, максимальное количество включений — 450 000 циклов."},
					]
				}
			},
			{
			slug: "paragraph",
			value: "Все они являются жидкостными ракетными двигателями и работают на самовоспламеняемой топливной паре несимметричный диметилгидразин (горючее) и тетраоксид азота (окислитель)."
			},
		]
	},
	{
		id: 9,
		name: "Манипулятор ERA",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/ScienceModal/main/16_Nauka.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/ScienceModal/9/modal-01.png")},
					{ id: 2, image: require("../images/ScienceModal/9/modal-02.png")}
				]
			},
		],
		content: [
			{
			slug: "heading",
			value: "Манипулятор ERA"
			},
			{
			slug: "paragraph",
			value: "Автономный передислоцируемый манипулятор ERA производства германской компании Fokker Space входит в состав штатных электромеханических средств технического обслуживания модуля «Наука». В качестве основного манипулятора российского сегмента станции он будет обеспечивать установку и снятие целевых нагрузок на поверхности станции и мониторинг состояния наружной поверхности станции, а также дистанционно управляемое перемещение космонавтов на переносном рабочем месте при выходах в открытый космос. В соответствии с графиком работ в ходе установки ERA на транспортировочные узлы фиксации был проведен высокоточный контроль положения манипулятора в сложенной конфигурации и комплексные испытания средств крепления на корпусе модуля."
			},
		]
	},
	{
		id: 10,
		name: "Топливные баки",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/ScienceModal/main/16_Nauka.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/ScienceModal/10/modal-01.png")},
					{ id: 2, image: require("../images/ScienceModal/10/modal-02.png")}
				]
			},
		],
		content: [
			{
			slug: "heading",
			value: "Топливные баки"
			},
			{
			slug: "paragraph",
			value: "Топливные баки двигательной установки предназначены для хранения запасов компонентов топлива до 2400 кг."
			},
			{
			slug: "paragraph",
			value: "Используется топливо: окислитель – азотный тетраоксид (АТ), горючее - несимметричный диметилгидразин (НДМГ)"
			},
		]
	},
	{
		id: 11,
		name: "Солнечная батарея",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/ScienceModal/main/16_Nauka.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/ScienceModal/11/modal-01.png")},
					{ id: 2, image: require("../images/ScienceModal/11/modal-02.png")}
				]
			},
		],
		content: [
			{
			slug: "heading",
			value: "Солнечные батареи"
			},
			{
			slug: "paragraph",
			value: "Солнечная батарея предназначена для преобразования солнечной энергии в электрическую для обеспечения электропитания бортовых систем МЛМ."
			},
			{
			slug: "paragraph",
			value: "Мощность солнечной батареи до 7,5 кВт."
			},
		]
	},
	{
		id: 12,
		name: "Радиационный теплообменник дополнительный (РТОд)",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/ScienceModal/main/16_Nauka.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/ScienceModal/12/modal-01.png")},
					{ id: 2, image: require("../images/ScienceModal/12/modal-02.png")}
				]
			},
		],
		content: [
			{
			slug: "heading",
			value: "Радиационный теплообменник дополнительный (РТОд)"
			},
			{
			slug: "paragraph",
			value: "Радиационный теплообменник дополнительный предназначен для увеличения возможностей МЛМ по сбросу тепла после установки и начала работы внутри МЛМ оборудования и научной аппаратуры с большим электропотреблением и тепловыделением. РТОд доставлен и хранился снаружи МИМ1, установлен на МЛМ манипулятором ERA."
			},
			{
			slug: "paragraph",
			value: "Хладопроизводительность РТОд до 2,3 кВт."
			},
		]
	},
	{
		id: 13,
		name: "Антенны Курс-А",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/ScienceModal/main/16_Nauka.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/ScienceModal/13/modal-01.png")},
					{ id: 2, image: require("../images/ScienceModal/13/modal-02.png")}
				]
			},
		],
		content: [
			{
			slug: "heading",
			value: "Антенны Курс-А"
			},
			{
			slug: "paragraph",
			value: "Антенны Курс-А использовались при стыковке МЛМ к СМ для работы системы стыковки Курс."
			},
		]
	},
	{
		id: 14,
		name: "Активный стыковочный агрегат",
		video: [
			{
				slug: "videoBlock",
				value: { id: 1, video: require("../images/ScienceModal/main/16_Nauka.mp4")},
				
			},
		],
		slider: [
			{
				slug: "modalSlider",
				value: [
					{ id: 1, image: require("../images/ScienceModal/14/modal-01.png")},
					{ id: 2, image: require("../images/ScienceModal/14/modal-02.png")}
				]
			},
		],
		content: [
			{
			slug: "heading",
			value: "Активный стыковочный аппарат"
			},
			{
			slug: "paragraph",
			value: "Активный стыковочный агрегат использовался для стыковки МЛМ к СМ и образования жесткого механического соединения модулей и герметичного тоннеля между СМ и МЛМ, соединение электрических интерфейсов и гидравлических магистралей модулей."
			},
		]
	},
];

export default {
	mksAboutInfo,
    mksStructure,
	scienceAboutInfo,
	scienceStructure,
};